import { Link, navigate } from 'gatsby'
import React, { useEffect } from 'react'

import { handleUserDeauth } from '@Services/handleUserDeauth'

const Index = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      navigate('/Login/')
    }
  })

  if (process.env.NODE_ENV === 'development') {
    return <>
      <Link to='/Detailing/'>Detailing</Link> <br />
      <Link to='/Inspection/'>Inspection</Link> <br />
      <Link to='/About/'>About</Link> <br />
      <Link to='/Warranty/'>Warranty</Link> <br />
      <Link to='/Shipping/'>Shipping</Link> <br />
      <Link to='/Contact/'>Contact</Link> <br />
      <Link to='/Login/'>Login</Link> <br />
      <Link to='/Search/'>Search</Link> <br />
      <Link to='/Confirmation/' state={{
        'confirmation_number': 162186,
        'buyer_name': 'John Smith 2.0',
        'email': 'JohnSmith2.0@FakeEmail.com',
        'phone': '123-456-7890',
        'address': '123 Fake Address',
        'city': 'Seminole',
        'state': 'FL',
        'is_cobuyer': true,
        'is_financing': true,
        'is_inspection': true,
        'is_warranty': false,
        'vehicle': {
          'stockid': 162186,
          'vin': '2GKALPEK0H6216405',
          'year': 2017,
          'make': 'GMC',
          'model': 'Terrain',
          'mileage': 71424,
          'city': 'Tracy',
          'state': 'CA',
          'price': 15000,
          'glamourphotos': [
            { 'listorder': 0, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312861.jpg' },
            { 'listorder': 1, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312361.jpg' },
            { 'listorder': 2, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312648.jpg' },
            { 'listorder': 3, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312433.jpg' },
            { 'listorder': 4, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312074.jpg' },
            { 'listorder': 5, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312290.jpg' },
            { 'listorder': 6, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312579.jpg' },
            { 'listorder': 7, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312511.jpg' },
            { 'listorder': 8, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312726.jpg' },
            { 'listorder': 9, 'image_url': 'https://www.efradmin.com/photos/y2020/m8/d16/EFR_162186_1600287312794.jpg' }
          ]
        }, 'reservation_date': '09/29/2020'
      }}>Confirmation</Link> <br />
      <button onClick={() => { handleUserDeauth({ response: { status: 401 } }) }}>De-Auth me</button>
    </>
  }

  return <div
    className='loading-ring-container'
    style={{ position: 'absolute', top: '50%', left: 0, right: 0, bottom: 0, margin: 0 }}
  >
    <div className='loading-ring' />
  </div>
}

export default Index
